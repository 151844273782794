import cabRow from '../images/cabrow.webp'
import manInCab from '../images/manincab.webp'

export default function TitleCards() {
    return (
        <>
            <section className="max-w-6xl mx-auto p-8 lg:p-16 md:px-16">
                <h1 className='font-bold text-xl sm:text-2xl lg:text-3xl mb-6'>Vehicles and the drivers</h1>
                {/* grid-cols-1 grid-rows-2 lg:grid-rows-1 lg:grid-cols-2 */}
                <div className="grid auto-cols-auto auto-rows-auto gap-8 sm:grid-cols-2">
                    <div>
                        <div className='w-full relative overflow-hidden block custom-image'>
                            <div className="absolute block inset-0">
                                <img className='w-full h-full object-cover scale-x-100' src={cabRow} alt="" />
                            </div>
                        </div>
                        <h2 className='font-semibold my-6'>Black Cabs</h2>
                        <p className='mb-6'>Black Cab is usually how licenced London taxis are referred to in London.</p>
                        <p>Black Cabs are the only door-to-door licenced transport service in London that can legally pick-up from the street without a prior booking. Black Cabs can use 90% of the Bus Lanes in London making them the fastest form of surface transport. Black Cabs are readily available from designated ranks, at all of London’s main transport hubs, such as Heathrow Airport, Waterloo and Victoria Stations to name but a few...</p>
                    </div>
                    <div>
                        <div className='w-full relative overflow-hidden block custom-image'>
                            <div className="absolute block inset-0">
                                <img className='h-full w-full object-cover scale-x-100' src={manInCab} alt="" />
                            </div>
                        </div>
                        <h2 className='font-semibold my-6'>World’s best taxi drivers</h2>
                        <p className='mb-6'>All Black Cab drivers have passed the world famous “Knowledge of London” test. Black Cab drivers have consistently been voted the world’s best taxi drivers by voters on Hotels.com </p>
                        <p>To learn more about the world’s toughest driving test click here</p>
                    </div>
                </div>
            </section>
        </>
    )
}