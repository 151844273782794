import ButtonBar from '../Components/ButtonBar'
import MobImage from '../Components/MobImage'
import SelectBar from '../Components/SelectBar'
import taxiLondonEye from '../images/taxilondoneye.webp'
import ImageWithText from '../Components/ImageWithText'
import TextBgSection from '../Components/TextBgSection'
import TitleCards from '../Components/TitleCards'
import ThreeCards from '../Components/ThreeCards'
import DownloadApps from '../Components/DownloadApps'
import SignUp from '../Components/SignUp'

export default function Home() {        

    return (
        <>
            <section className=" mt-16 p-0 m-auto bg-white min-h-auto h-auto w-full flex relative">
                <div className='z-0 w-full flex relative bg-cover overflow-hidden bg-center m-auto h-auto bg-no-repeat '>
                    <div className="grid visible inset-0 absolute overflow-hidden z-0">
                        <div className='h-full w-full inset-0 absolute block'>
                            <img src={taxiLondonEye} className="hidden lg:block pb-0 h-full w-full object-cover scale-x-100" alt="" />
                        </div>
                    </div>
                    <div className="max-w-6xl z-10 self-start mx-auto w-full">
                        <div className="lg:p-16 lg:flex lg:justify-end">
                            <div className='lg:max-w-lg bg-white md:px-16'>
                                <SelectBar/>
                            </div>
                        </div>
                    </div>
                </div>  
            </section>
                    
            <div className='lg:hidden'>
                <MobImage/>
            </div>

            <ImageWithText/>
            
            <TextBgSection/>
                
            <TitleCards/>

            <ThreeCards/>

            <DownloadApps/>

            <SignUp/>
        </>
    )
}