import { useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPlus, faMinus } from '@fortawesome/free-solid-svg-icons'

type AppProps = {
    qaobj: any;
  }; 

export default function Accordion({qaobj}:AppProps){

    const [ clicked, setClicked ] = useState(false);

    const plus = <FontAwesomeIcon icon={faPlus} />
    const minus = <FontAwesomeIcon icon={faMinus} />

    return(
        <>

                <div 
                className="flex justify-between items-center border-b border-gray-100 pb-4 mb-4"
                onClick={()=>{
                    setClicked(!clicked)
                }}
                >
                    <h2 className="text-2xl font-semibold">{qaobj.question}</h2>
                    <button
                        >
                        {
                        clicked ? 
                            <div >{minus}</div>
                        :
                            <div>{plus}</div>
                        }
                    </button>                    
                </div>


                <div className={ clicked ? 'block' : 'hidden' }>
                    {qaobj.answer.map((item:any) =>{
                        return(
                            <>
                                <p className='my-6'>{item}</p>
                            </>
                        )
                    })}
                </div>
            
        </>
    )
}