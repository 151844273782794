import Accordion from './Accordion';

export default function FAQ() {
 
    const QAObj = [
        {
            "question":"How do I create an account?",
            "answer": [
                "Passengers do not have to register their details to use the BlackCab.Com hailing service.",
                "If you prefer to register your card payment and be matched with a designated driver, then please click on the link and follow the steps to create an account "
            ]
        },
        {
            "question":"What are the two type of hirings?",
            "answer": [
                "Passengers do not have to register their details to use the BlackCab.Com hailing service.",
                "If you prefer to register your card payment and be matched with a designated driver, then please click on the link and follow the steps to create an account "
            ]
        },
        {
            "question":"Will I pay more than the metered fare?",
            "answer": [
                "Passengers do not have to register their details to use the BlackCab.Com hailing service.",
            ]
        },
    ];

    return (
        <>
            <section className='max-w-6xl mx-auto px-8 md:px-16 py-8 md:py-16 '>
            
            <h1 className="text-3xl font-bold mb-8">Frequently Asked Questions</h1>

            {
                QAObj.map((item, index) => {
                   
                    return(
                        <Accordion qaobj={item}/>
                    )
                })
            }
            
        
            </section>
            
        </>
    )
}