import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faUsers, faCameraRetro, faHouse} from '@fortawesome/free-solid-svg-icons'


export default function ThreeCards() {

    const users = <FontAwesomeIcon icon={faUsers} />
    const cam = <FontAwesomeIcon icon={faCameraRetro} />
    const house = <FontAwesomeIcon icon={faHouse} />

    return(
        <>
            <section className='max-w-6xl mx-auto px-8 md:px-16 pt-8 pb-16 lg:py-16'>
                <div className="grid grid-cols-4 md:grid-cols-8 lg:grid-cols-12 gap-x-4 md:gap-x-8 gap-y-8">
                    <div className='grid col-span-4 self-start order-0'>
                        <div className="flex flex-col items-start">
                            <div className='mr-8 mb-6'>{users}</div>
                            <div>
                                <h2 className='mb-6 font-semibold'>About Us</h2>
                                <p>BlackCab.Com is a collaboration between Cabvision Network and TaxiWorld, who collectivelly have decades of expereince within the London Taxi industry.</p>
                            </div>
                        </div>
                    </div>
                    <div className='grid col-span-4 self-start order-0'>
                        <div className="flex flex-col items-start">
                            <div className='mr-8 mb-6'>{cam}</div>
                            <div>
                                <h2 className='mb-6 font-semibold'>Celebrity Gallery</h2>
                                <p>Take a look through the driver gallery and spot some famous faces</p>
                            </div>
                        </div>
                    </div>
                    <div className='grid col-span-4 self-start order-0'>
                        <div className="flex flex-col items-start">
                            <div className='mr-8 mb-6'>{house}</div>
                            <div>
                                <h2 className='mb-6 font-semibold'>United Trade Action Group</h2>
                                <p>United Trade Action Group is a collaboration between cab drivers and taxi stakeholders designed to maintain and improve taxi standards. Donate today. </p>
                            </div>
                        </div>
                    </div>
                    
                </div>
            </section>
        </>
    )
}