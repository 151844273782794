import React from "react";
import {
  BrowserRouter,
  Routes,
  Route
} from "react-router-dom";
import './App.css';
import Home from "./Pages/Home";
import Demo from "./Pages/Demo";
import HeaderOne from "./Layout/HeaderOne";
import Footer from "./Layout/Footer";

function App() {
  return (

    <div className="App">
     
      

      <BrowserRouter>

        <HeaderOne/>

        
        <Routes>

        

        <Route
            path='/'
            element={
              <Home/>}
          />

        <Route
            path='/demo'
            element={
              <Demo/>}
          />

        </Routes>

        <Footer/>

      </BrowserRouter>

    </div>

  );
}

export default App;
