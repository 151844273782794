

export default function ButtonBar(props:any) {
    console.log(props)
    return(
        <>
            <div className="p-8">
                {

                    props.props === false && 
                        <>

                            <h2 className="text-left text-2xl font-bold mb-2">Display your location to nearby taxi drivers to notify them that you are looking for a taxi </h2>
                            <h3 className="text-left">You cannot book using the virtual hand, there is no obligation, this is the cheapest method of hiring</h3>
                            
                        </>
                       
                       
                }

                {

                props.props === true && 
                    (
                        <>
                            <h2 className="text-left text-2xl font-bold mb-2">This is your best chance of securing a Black Cab. </h2>
                            <h3 className="text-left">Because the driver has the option of starting the taximeter at acceptance of your booking rather than at your location.</h3>
                        </>
                       
                    )
                
                }
               

                <div className="py-6">

                    {
                        props.props === false && (
                                <>
                                    <button className="p-4 bg-black text-white w-full mb-4">Hail</button>
                                   
                                </>
                        )
                    }
                    {
                        props.props === true && (
                                <>
                                    <button className="p-4 bg-black text-white w-full mb-4">Hail</button>
                                    <button className="bg-gray-300 p-4 w-full">Schedule for later</button>
                                </>
                        )
                    }
                    
                </div>
               
            </div>
        </>
    )
}