import woman from '../images/womancab.webp'
import access from '../images/access.webp'
import levc from '../images/levc.webp'

export default function SmallerClassic({order}:any) {

    return(
        <>
            {
                order === 1 && (
                    <section className='max-w-6xl mx-auto px-8 md:px-16 py-8 md:py-16 '>
                        <div className="grid auto-cols-auto md:grid-cols-2 gap-8">

                            <div className='self-center'>
                                <h1 className='text-3xl font-bold mb-6'>Accessibility</h1>
                                    <p className='mb-6'>All Black Cabs are 100% wheelchair accessible. Amongst other features, Eech vehicle has yellow handles and edges to assist the partially sited as well as an intercom and hearing induction loop fitted as standard.</p>  
                            </div>
                            
                            <div className="flex items-center justify-center">
                                <img className='w-full object-cover scale-x-100' src={access} alt="" />
                            </div>
                        
                        </div>
                    </section>
                )
            }
            {
                order === 2 && (
                    <section className='max-w-6xl mx-auto px-8 md:px-16 py-8 md:py-16 '>
                        <div className="grid auto-cols-auto md:grid-cols-2 gap-8">
                       
                            <div className="flex items-center justify-center">
                                <img className='w-full object-cover scale-x-100' src={woman} alt="" />
                            </div>

                            <div className='self-center'>
                                <h1 className='text-3xl font-bold mb-6'>Diversity</h1>
                                    <p className='mb-6'>The knowledge of London test is open to any person who wishes to apply themselves to be the best in class.</p>  
                                    <p className='mb-6'>No matter what background you are from, what race, what gender, the opportuniy remains the same in this merit based system.</p>
                            </div>
   
                        </div>
                    </section>
                )
            }
            {
                order === 3 && (
                    <section className='max-w-6xl mx-auto px-8 md:px-16 py-8 md:py-16 '>
                        <div className="grid auto-cols-auto md:grid-cols-2 gap-8">

                            

                            <div className='self-center'>
                                <h1 className='text-3xl font-bold mb-6'>Environment</h1>
                                    <p className='mb-6'>Over 50% of Black Cabs are environmentally friendly running on electric or clean fuels. The London Black Cab trade has invested over £300 million in clean vehicles since 2018.</p>  
                            </div>

                            <div className="flex items-center justify-center">
                                <img className='w-full object-cover scale-x-100' src={levc} alt="" />
                            </div>
                    
                        </div>
                    </section>
                )
            }
        </>
    )
}