import { Navigation, Pagination, Scrollbar, A11y } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import 'swiper/css/scrollbar';
import taxione from '../images/taxi1.webp'
import taxitwo from '../images/taxi2.webp'
import taxithree from '../images/taxi3.webp'

export default function SlideShow() {
  return (
    <>
        <section className="max-w-6xl mx-auto px-8 md:px-16 pt-8 pb-16 lg:py-16">
            
            <h1 className="text-3xl font-bold mb-6">Our most popular options</h1>

            <h2 className="text-2xl mb-6">Electronically hail a cab, jump in and go!</h2>

            <Swiper
                
                modules={[Navigation, Pagination, Scrollbar, A11y]}
                spaceBetween={50}
                // @ts-expect-error:
                navigation={{ clickable: true }}
                breakpoints={{
                    320: {
                    slidesPerView: 1,
                    slidesPerGroup:1,
                    },
                    768: {
                    slidesPerView: 2,
                    slidesPerGroup:2,
                    },
                    1024: {  
                        slidesPerView: 3,
                        slidesPerGroup:3,
                    },
                }}
                >
                <SwiperSlide>

                    <div className="flex justify-center mb-6">
                        
                        <img src={taxione} alt="" />
                       
                    </div>

                    <div className="flex flex-col items-start">
                        <h3 className='text-xl mb-6'>EV Black Cab</h3>
                        <p>Guaranteed to be a zero emission capable Black Cab</p>
                    </div>
                    
                </SwiperSlide>
                <SwiperSlide>

                    <div className="flex justify-center mb-6">
                        <img src={taxitwo} alt="" />
                        
                    </div>

                    <div className="flex flex-col items-start">
                        <h3 className='text-xl mb-6'>6 seat Black Cab</h3>
                        <p>Guaranteed to be Black Cab licenced to carry 6 passengers</p>
                    </div>

                </SwiperSlide>
                <SwiperSlide>

                    <div className="flex justify-center mb-6">
                        <img src={taxithree} alt="" />
                        </div>

                    <div className="flex flex-col items-start">
                        <h3 className='text-xl mb-6'>1st Black Cab available</h3>
                        <p>Click here if you’re in a hurry and we’ll match you with the 1st available Black Cab</p>
                    </div>

                </SwiperSlide>
                
            </Swiper>
        </section>
        
    </>

  );
};