import womanphone from '../images/womanPhone.webp'

export default function HowBlackCabWorks() {

    const arrObj = [
        {
            "step": 1,
            "header":"Open the app",
            "text":"If you have registered your payment details, you will see two options to hail a Black Cab. If you have not registered your payment details, you will see only one option - ‘the virtual hand’ which notifies drivers in your local vicinity that you are looking for a cab. There is no booking and you are free to take the first cab that you see, as there is no commitment",
        },
        {
            "step": 2,
            "header":"A rider opens the app",
            "text":"When you electronically hail a taxi, the app will search for the nearest driver.",
        },
        {
            "step": 3,
            "header":"A rider opens the app",
            "text":"If you hail with your card details registered, you will see the Black Cab make it’s way to you",
        }
    ];

    return(
        <>

            <section className='max-w-6xl mx-auto px-8 md:px-16 py-8 md:py-16 '>
                <div className="grid auto-col-auto">

                    {
                        arrObj.map(item => {
                            
                            return(
                                <div>
                                    <div className="grid auto-col-auto md:grid-cols-8 lg:grid-cols-12 gap-9 pb-6 mb-6 md:pb-4 md:mb-1 md:border-l-0 border-l border-black pl-4  md:pr-4 md:pl-0 custom-dot">
                                        <div className="col-span-4">
                                            <img className='h-full w-full object-cover' src={womanphone} alt="" />
                                        </div>
                                        <div className="md:border-l md:border-black md:pl-4 col-span-4 lg:col-span-8 custom-dot-one">
                                            <h1 className='text-gray-600 mb-6'>Step {item.step}</h1>
                                            <h2 className='mb-6 font-bold text-lg'>{item.header}</h2>
                                            <p className='mb-6'>{item.text}</p>
                                        </div>
                                    </div>
                                </div>
                            )
                        })
                    }

                    
                    
                </div>
            </section>

            
        </>
    )

}