import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faArrowRight} from '@fortawesome/free-solid-svg-icons'

export default function SignUp() {

    const arrowRight = <FontAwesomeIcon icon={faArrowRight} />

    return(
        <>
            <section className='max-w-6xl mx-auto px-8 md:px-16 py-8 md:py-16 '>
                <div className="flex flex-col space-y-6 ">
                        
                        <div className="flex flex-col lg:flex-row space-y-4 lg:space-y-0 lg:space-x-6">
                            
                            <div className="lg:w-1/2 flex justify-between items-center border-b border-black space-4 pb-4">
                                
                               
  
                                <h1 className='font-bold text-2xl'>Sign Up To Register as a Driver</h1>
                                
                                <div className="p-4">
                                    <div>{arrowRight}</div>
                                </div>

                            </div>
                    
                            <div className="lg:w-1/2 flex justify-between items-center border-b border-black space-4 pb-4">
                               
                                <h1 className='font-bold text-2xl'>Sign up if you wish to register your payment details</h1>
                                
                                <div className="p-4">
                                    <div>{arrowRight}</div>
                                </div>

                            </div>
                        </div>
                        
                   
                    
                </div>
            </section>
        </>
    )
}