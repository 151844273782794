import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faArrowRight} from '@fortawesome/free-solid-svg-icons'
import apple from '../images/apple.webp'
import android from '../images/android.webp'

export default function ThreeCards() {

    const arrowRight = <FontAwesomeIcon icon={faArrowRight} />

    return(
        <>
            <div className="bg-gray-100">
                <section className='max-w-6xl mx-auto px-8 md:px-16 py-8 md:py-16 bg-gray-100'>
                <div className="flex flex-col space-y-6">
                    
                    
                        <h1 className='font-bold text-3xl'>Find our apps in-store</h1>
                        
                        <div className="flex flex-col lg:flex-row space-y-4 lg:space-y-0 lg:space-x-6">
                            
                            <div className="bg-white flex justify-start items-center border border-black space-4">
                                
                                <div className="p-4">
                                    <img className='icon-size' src={apple} alt="" />
                                </div>

                                <h2 className='font-semibold text-xl'>Download on the Apple App Store</h2>
                                
                                <div className="p-4">
                                    <div>{arrowRight}</div>
                                </div>

                            </div>
                    
                            <div className="bg-white flex justify-start items-center border border-black space-4">
                                
                                <div className="p-4">
                                    <img className='icon-size' src={android} alt="" />
                                </div>

                                <h2 className='font-semibold text-xl'>Download on the Android App Store</h2>
                                
                                <div className="p-4">
                                    <div>{arrowRight}</div>
                                </div>

                            </div>
                        </div>
                        
                
                    
                    </div>
                </section>
            </div>
            
        </>
    )
}