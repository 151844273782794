

export default function ImageWithText() {
    return (
        <>
            <div className="custom-class">
            
                <section className="max-w-6xl mx-auto">
                    <div className="px-8 md:px-16 py-16 ">
                        <div className="flex flex-col justify-start">
                            <h1 className="text-left mb-6 text-xl lg:text-3xl font-bold">Black Cab for Business</h1>
                            <h2 className="text-left mb-6 ">Transform the way your company travels</h2>
                            <button className="w-36 bg-black text-white py-4 px-6">See how</button>
                        </div>
                    </div>
                </section>
            </div>
        </>
    )
}