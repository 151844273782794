

export default function TextBgSection() {
    return(
        <>
            <div className="bg-black">
                <section className="max-w-6xl mx-auto">
                    <div className="bg-black px-8 md:px-16 py-16">
                        <div className=" text-white flex flex-col justify-start">
                            <h1 className="text-left mb-6 text-xl lg:text-3xl font-bold">What we do</h1>
                            <h2 className="text-left mb-6 ">BlackCab.com is a hailing app designed to match you with one of London’s 15.000 iconic Black Cabs. Every driver has completed the world famous ”Knowledge of London” test, which is often referred to as the world’s hardest driving test. We have a range of cabs available to select via the app, including electric or 6 seater. </h2>
                            <h2 className="text-left mb-6 ">You can either register your details in advance to be able to book a cab or get going straight away by electronically raising your hand to make yourself visible to cabs in your immediate vicinity. You choose how you wish to pay, cash  as well as card payments are available on every ride.</h2>
                            <button className="w-64 bg-white text-black py-4 px-6">Sign up for Black Cab</button>
                        </div>
                    </div>
                </section>
            </div>
        </>
    )
}