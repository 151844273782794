import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faMobile, faBagShopping, faMoneyBill, faChartSimple, faWheelchair, faGift} from '@fortawesome/free-solid-svg-icons'



export default function SixCards() {

    const mobile = <FontAwesomeIcon icon={faMobile} />
    const shopping = <FontAwesomeIcon icon={faBagShopping} />
    const money = <FontAwesomeIcon icon={faMoneyBill} />
    const chart = <FontAwesomeIcon icon={faChartSimple} />
    const wheelchair = <FontAwesomeIcon icon={faWheelchair} />
    const gift = <FontAwesomeIcon icon={faGift} />

    return(
        <>
            <section className='max-w-6xl mx-auto px-8 md:px-16 pt-8 pb-16 lg:py-16'>
            
                <div className="grid grid-cols-4 md:grid-cols-8 lg:grid-cols-12 gap-x-4 md:gap-x-8 gap-y-8">
                    <div className='grid col-span-4 self-start order-0'>
                        <div className="flex flex-col items-start">
                            <div className='mr-8 mb-6'>{mobile}</div>
                            <div>
                                <h2 className='mb-6 font-semibold'>Why use black cab?</h2>
                                <p>Only Black Cab drivers have the world famous Knowledge of London</p>
                            </div>
                        </div>
                    </div>
                    <div className='grid col-span-4 self-start order-0'>
                        <div className="flex flex-col items-start">
                            <div className='mr-8 mb-6'>{shopping}</div>
                            <div>
                            <h2 className='mb-6 font-semibold'>Why use black cab?</h2>
                                <p>All drivers have passed an extensive background check and are licenced by Transport for London</p>
                            </div>
                        </div>
                    </div>
                    <div className='grid col-span-4 self-start order-0'>
                        <div className="flex flex-col items-start">
                            <div className='mr-8 mb-6'>{money}</div>
                            <div>
                                <h2 className='mb-6 font-semibold'>Why use black cab?</h2>
                                <p>Regulated fares, no surge pricing</p>
                               
                            </div>
                        </div>
                    </div>
                    <div className='grid col-span-4 self-start order-0'>
                        <div className="flex flex-col items-start">
                            <div className='mr-8 mb-6'>{chart}</div>
                            <div>
                                <h2 className='mb-6 font-semibold'>Why use black cab?</h2>
                                <p>Purpose built vehicles licenced to carry 5 or 6 persons</p>
                               
                            </div>
                        </div>
                    </div>
                    <div className='grid col-span-4 self-start order-0'>
                        <div className="flex flex-col items-start">
                            <div className='mr-8 mb-6'>{wheelchair}</div>
                            <div>
                                <h2 className='mb-6 font-semibold'>Why use black cab?</h2>
                                <p>100% Wheelchair accessible</p>
                               
                            </div>
                        </div>
                    </div>
                    <div className='grid col-span-4 self-start order-0'>
                        <div className="flex flex-col items-start">
                            <div className='mr-8 mb-6'>{gift}</div>
                            <div>
                                <h2 className='mb-6 font-semibold'>Why use black cab?</h2>
                                <p>Pay the way you choose - cash, card in vehicle or register</p>
                               
                            </div>
                        </div>
                    </div>
                    
                </div>
            </section>
        </>
    )
}