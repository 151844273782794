import { Link } from "react-router-dom"
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faGlobe, faLocationDot } from '@fortawesome/free-solid-svg-icons'
import { faFacebook, faTwitter, faYoutube, faLinkedin, faInstagram } from '@fortawesome/free-brands-svg-icons'
import googleplay from "../images/googleplay.svg"
import applestore from "../images/applestore.svg"

export default function Footer() {

    const globe = <FontAwesomeIcon icon={faGlobe} />
    const dot = <FontAwesomeIcon icon={faLocationDot} />

    const facebook = <FontAwesomeIcon icon={faFacebook} />
    const twitter = <FontAwesomeIcon icon={faTwitter} />
    const youtube = <FontAwesomeIcon icon={faYoutube} />
    const linkedin = <FontAwesomeIcon icon={faLinkedin} />
    const instagram = <FontAwesomeIcon icon={faInstagram} />

    return (
        <>  
            <div className="bg-black">
                <div className="max-w-6xl mx-auto bg-black containerr px-8 md:px-16">
                    <div className="bg-black py-10 flex flex-col items-start">
                        <Link to="/home">
                            <h1 className="text-white py-14 font-bold">Black Cab</h1>
                        </Link>
                        <h1 className="text-white text-sm mb-6 font-semibold">A Useful Link</h1>
                        <p className="text-white text-sm mb-4">Download Useful Document</p>
                        <p className="text-white text-sm">Download Useful Document</p>

                        <div className="my-6"></div>

                        {/* className="grid grid-cols-1 md:grid-cols-2 grid-rows-4 md:grid-rows-2 w-full space-y-6 lg:space-y-0 lg:grid-cols-4 lg:grid-rows-1" */}

                        <div className="grid space-y-3 md:space-y-0 grid-flow-row md:grid-flow-col w-full ">
                            <div>
                                <h1 className="text-white mb-4 font-bold text-left">Company</h1>
                                <p className="text-white text-sm mb-4 text-left">About us</p>
                                <p className="text-white text-sm mb-4 text-left">Our offerings</p>
                                <p className="text-white text-sm mb-4 text-left">Careers</p>
                            </div>

                            <div>
                                <h1 className="text-white mb-4 font-bold text-left">Products</h1>
                                <p className="text-white text-sm mb-4 text-left">Ride</p>
                                <p className="text-white text-sm mb-4 text-left">Virtual Hand</p>
                                <p className="text-white text-sm mb-4 text-left">Hail a Black Cab</p>
                            </div>

                          

                            <div>  
                                <h1 className="text-white mb-4 font-bold text-left">Travel</h1>
                                <p className="text-white text-sm mb-4 text-left">Airports</p>
                                <p className="text-white text-sm mb-4 text-left">Cities</p>
                            </div>
                        </div>

                        <div className="grid space-y-3 md:space-y-0 md:space-x-4 grid-flow-row md:grid-flow-col w-full">
                            <div className="flex space-x-8 items-center">
                                <div className="text-white">{facebook}</div>
                                <div className="text-white">{twitter}</div>
                                <div className="text-white">{youtube}</div>
                                <div className="text-white">{linkedin}</div>
                                <div className="text-white">{instagram}</div>
                            </div>

                            <div className="flex flex-col md:flex-row md:items-center md:space-x-4">
                                <div className="flex">
                                    <div className="text-white w-6 -ml-1 ">{globe}</div>
                                    <div className="text-white ml-4 md:ml-2 text-sm">English</div>
                                </div>

                                <div className="flex">
                                    <div className="text-white -ml-1 w-6">{dot}</div>
                                    <div className="text-white ml-4 md:ml-2 text-sm">City of London</div>
                                </div>
                            </div>

                            <div className="grid grid-cols-2 grid-rows-1">
                                <img width={100}  className="self-center mr-4" src={googleplay} alt="" />
                                <img width={100}  className="self-center" src={applestore} alt="" />
                            </div>
                        </div>

                        



                        <p className="text-sm text-gray-300 my-8">&copy; {new Date().getFullYear()} Black Cab </p>

                        <div className="flex w-8/12 space-x-3">
                            <p className="text-sm text-gray-300 my-8">Privacy</p>
                            <p className="text-sm text-gray-300 my-8">Accessibility</p>
                            <p className="text-sm text-gray-300 my-8">Terms</p>
                        </div>
                
                    </div>
                </div>
            </div>
        </>
    )

}