import { useState } from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faHand, faCar, faAddressCard} from '@fortawesome/free-solid-svg-icons'
import ButtonBar from './ButtonBar'

export default function SelectBar() {

    const hand = <FontAwesomeIcon icon={faHand} />
    const car = <FontAwesomeIcon icon={faCar} />
    const reg = <FontAwesomeIcon icon={faAddressCard} />

    const [ clicked, setClicked ] = useState(false);

    return(
        <>
            <div className="flex max-w-md ">
                <div className="grid grid-cols-3 px-4 py-6 w-full ">
                    
                    <div 
                    onClick={()=>setClicked(false)}
                    className="flex flex-col">
                        <div className='mb-3'>{hand}</div>
                        <p className='text-sm text-center font-semibold'>Virtual Hand</p>
                    </div>

                    <div 
                    onClick={()=>setClicked(true)}
                    className="flex flex-col">
                        <div className='mb-3'>{car}</div>
                        <p className='text-sm text-center font-semibold'>Book a <br/> Black Cab</p>
                    </div>
                </div>
                
            </div>   
            <div className="px-4 grid grid-cols-3 max-w-md">
                    {
                        clicked === false && (
                            <div className='h-1.5 bg-black'></div>
                        )
                    }

                    {/* <div className='h-1.5 bg-black'></div> */}
                    <div></div>
                    
                    {
                        clicked === true && (
                            <div className='h-1.5 bg-black'></div>
                        )
                    }

                    {/* <div ></div> */}
            </div>   
            <div className='border-b border-gray-200' ></div> 

            <ButtonBar props={clicked}/>
        </>
    )
}