import hail from '../images/hail2.webp'

export default function ClassicImageWithText() {
    return(
        <>
            <section className='max-w-6xl mx-auto px-8 md:px-16 py-8 md:py-16 '>
                <div className="grid auto-cols-auto md:grid-cols-2 gap-8">
                    <div className='w-full relative overflow-hidden block custom-image'>
                        <div className="absolute block inset-0">
                            <img className='w-full h-full object-cover scale-x-100' src={hail} alt="" />
                        </div>
                    </div>
                    <div>
                        <h1 className='text-3xl font-bold mb-6'>Hailing a cab</h1>
                        <p className='mb-6'>The main differentiators between BlackCab.com and other similar services is that the passenger is not required to register to use our services, as Black Cabs can be hailed from the street - alternatively if you choose to register your payment details that the hiring starts at the time of acceptance by the driver.</p>
                        <p className='mb-6'>There are two hiring options:</p>
                        <p className='mb-6'>1). Passengers have the option to raise a virtual hand to notify drivers that they require a cab, and if there is one locally, the cab will come to them or </p>
                        <p className='mb-6'>2). Passengers can register their payment details and we will match you with the nearest available driver, who shall come directly to you. </p>
                        <p className='mb-6'>The later hiring, will give passengers a far greater chance of hiring a cab, as the driver has a financial incentive, at the point of acceptance, to complete the hail request.</p>
                    </div>
                </div>
            </section>
        </>
    )
}