

export default function SignUpToday() {
    return(
        <>
            <div className="bg-gray-100">
                <section className="max-w-6xl mx-auto px-8 md:px-16 py-8 md:py-16">
                    <h1 className="text-3xl font-bold mb-6">Sign Up Today</h1>
                    <p className="">Join the evolution. register your payment details now and have access to our entire fleet of black cab drivers or simply download the app to electronically hail a cab</p>

                    <div className="mt-6 flex flex-col justify-start">
                        <button className="w-64 font-bold bg-black px-8 py-4 text-white">Sign Up Now</button>
                    </div>

                    


                </section>
            </div>
            
        </>
    )
}