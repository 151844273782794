import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCertificate, faUsers, faMoneyBill} from '@fortawesome/free-solid-svg-icons'


export default function ImageThreeCards() {

    const cert = <FontAwesomeIcon icon={ faCertificate } />
    const users = <FontAwesomeIcon icon={ faUsers } />
    const money = <FontAwesomeIcon icon={ faMoneyBill } />

    return(
        <>
            <section className='max-w-6xl mx-auto px-8 md:px-16 pt-8 pb-16 lg:py-16'>

                <h1 className='text-3xl font-bold mb-6'>Safety Matters</h1>
                <h1 className='text-3xl font-bold mb-6'>Safest form of transport</h1>
                
                <div className="grid grid-cols-4 md:grid-cols-8 lg:grid-cols-12 gap-x-4 md:gap-x-8 gap-y-8">
                    <div className='grid col-span-4 self-start order-0'>
                        <div className="flex flex-col items-start">
                            <div className='my-8'>{cert}</div>
                            <div>
                                <h2 className='mb-6 font-semibold'>Drivers</h2>
                                <p>Every driver has passed the world’s toughest driving test and has completed a background check in order to receive their licence from Transport for London, the transport regulator in London</p>
                            </div>
                        </div>
                    </div>
                    <div className='grid col-span-4 self-start order-0'>
                        <div className="flex flex-col items-start">
                        <div className='my-8'>{users}</div>
                            <div>
                                <h2 className='mb-6 font-semibold'>Vehicles</h2>
                                <p className='mb-2'>Each taxi in London is purpose built to meet the highest saftey standards. All cabs have bi-annual tests to ensure the vehicle is fit for use.</p>
                                <p>A partition divides the passenger from the driver and the passenger compartmnt has an intercom and many accessible features to assist apersons with disabilities. All london’s taxis are wheelchair accessible.. There is no extra cost for an accessible vehicle</p>
                            </div>
                        </div>
                    </div>
                    <div className='grid col-span-4 self-start order-0'>
                        <div className="flex flex-col items-start">
                        <div className='my-8'>{money}</div>
                            <div>
                                <h2 className='mb-6 font-semibold'>Payments</h2>
                                <p className='mb-2'>You can pay your journey in cash, by registering your card in advance or by card in the vehicle. by using the payment device in the passenger compartment.</p>
                                <p>Passengers chose the payment method that best suits them. There are no additional charges for using your card and electronic receipts for card payments are avaialble in all vehicles.</p>
                            </div>
                        </div>
                    </div>
                    
                </div>
            </section>
        </>
    )
}