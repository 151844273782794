import Cards from "../Demo/Cards"
import ClassicImageWithText from "../Demo/ClassicImageWithText"
import FAQ from "../Demo/FAQ"
import HowBlackCabWorks from "../Demo/HowBlackCabWorks"
import ImageThreeCards from "../Demo/ImageThreeCards"
import SignUpToday from "../Demo/SignUpToday"
import SixCards from "../Demo/SixCards"
import SlideShow from "../Demo/SlideShow"
import SmallerClassic from "../Demo/SmallerClassic"
import TitleThreeCards from "../Demo/TitleThreeCards"

export default function Demo() {

    return(
        <>
            <section className="mt-16"></section>

            <ImageThreeCards/>

            <TitleThreeCards/>

            <FAQ/>

            <ClassicImageWithText/>

            <SmallerClassic order={1}/>

            <SmallerClassic order={2}/>

            <SmallerClassic order={3}/>
            
            <Cards/>

            <SixCards/>

            <SlideShow/>


            <HowBlackCabWorks/>

            <SignUpToday/>
        </>
    )
}