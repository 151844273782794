import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faIdCard, faTaxi, faMapLocation} from '@fortawesome/free-solid-svg-icons'
import happypassengers from '../images/crop.webp'


export default function ImageThreeCards() {

    const idcard = <FontAwesomeIcon icon={faIdCard} />
    const taxi = <FontAwesomeIcon icon={faTaxi} />
    const mapLoc = <FontAwesomeIcon icon={faMapLocation} />

    return(
        <>
            <section className='max-w-6xl mx-auto px-8 md:px-16 pt-8 pb-16 lg:py-16'>
                <h1 className='text-3xl font-bold mb-6'>Why use Black Cabs?</h1>
                <img src={happypassengers} alt=""  className='w-full mb-8'/>
                <div className="grid grid-cols-4 md:grid-cols-8 lg:grid-cols-12 gap-x-4 md:gap-x-8 gap-y-8">
                    <div className='grid col-span-4 self-start order-0'>
                        <div className="flex flex-col items-start">
                            <div className='mr-8 mb-6'>{idcard}</div>
                            <div>
                                <h2 className='mb-6 font-semibold'>Knowledgable drivers</h2>
                                <p>All drivers on the platform have completed the world’s toughest driving test, learning over 20,000 roads! In addition, they have passed background checks to be licecned by Transport for London</p>
                            </div>
                        </div>
                    </div>
                    <div className='grid col-span-4 self-start order-0'>
                        <div className="flex flex-col items-start">
                            <div className='mr-8 mb-6'>{taxi}</div>
                            <div>
                                <h2 className='mb-6 font-semibold'>Purpose built Cabs</h2>
                                <p>Every Black Cab in London has been purposely built to meet an exacting standard set by Transport for London as the transport regulator.. Taxis are licenced to carry either 5-6 persons and over 1 in 3 taxis are now zero emmission capable, making the London taxi fleet one of the world’s cleanest</p>
                            </div>
                        </div>
                    </div>
                    <div className='grid col-span-4 self-start order-0'>
                        <div className="flex flex-col items-start">
                            <div className='mr-8 mb-6'>{mapLoc}</div>
                            <div>
                                <h2 className='mb-6 font-semibold'>Your choice</h2>
                                <p className='mb-2'>Book however you prefer - raise your virtual hand to encourage drivers to drive to you or book direct with a driver for peace-of-mind.</p>
                                <p>Choose your preferred type of cab and pay for your jouney in cash, by card on file or just pay in the cab.</p>
                            </div>
                        </div>
                    </div>
                    
                </div>
            </section>
        </>
    )
}