import { Link } from "react-router-dom"
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faBars, faChevronDown, faGlobe, faGrip} from '@fortawesome/free-solid-svg-icons'
import { faUser } from '@fortawesome/free-regular-svg-icons'


export default function Header() {
    const bars = <FontAwesomeIcon icon={faBars} />
    const user = <FontAwesomeIcon icon={faUser} />
    const chevron = <FontAwesomeIcon icon={faChevronDown} />
    const globe = <FontAwesomeIcon icon={faGlobe} />
    const freeIconGrid = <FontAwesomeIcon icon={faGrip} />

    return (
        <>
        
            <div className="relative">
                <div className="bg-black z-20 fixed top-0 left-0 right-0">



                    <div className="max-w-6xl mx-auto flex justify-between items-center py-2 px-8 md:px-16 bg-black">
                        <div className="flex items-center lg:space-x-6">
                            <Link to="/">
                                <h1 className="text-white font-bold text-left">Black Cab</h1>
                            </Link>
                            <div className="flex items-center space-x-2">
                                <button className="hidden lg:block text-white font-semibold text-sm">Company</button>
                                <div className="hidden lg:block text-white text-xs">{chevron}</div>
                            </div>
                            <Link to="/home">
                                <h1 className="hidden lg:block text-white font-bold text-sm">Safety</h1>
                            </Link>
                            <Link to="/demo">
                                <h1 className="hidden lg:block text-white font-bold text-sm">Demo</h1>
                            </Link>
                        </div>
                        

                        <div className="flex items-center lg:space-x-4">

                            <div className="flex space-x-1 lg:space-x-0 items-center">
                                <div className="text-white px-2.5 hidden lg:block">{globe}</div>
                                <p className="hidden lg:block text-white text-sm">EN</p>
                            </div>

                            <div className="flex space-x-1 lg:space-x-0 items-center">
                                <div className="text-white px-2.5 hidden lg:block">{freeIconGrid}</div>
                                <p className="hidden lg:block text-white text-sm">Products</p>
                            </div>

                            <div className="flex space-x-1 lg:space-x-0 items-center">
                                <div className="text-white px-2.5 ">{user}</div>
                                <p className="hidden lg:block text-white text-sm">Log in</p>
                            </div>
                            
                            
                            <button className="text-sm font-bold px-3 py-1.5 my-2 bg-white rounded-full">Sign Up</button>

                            <div className="text-white px-2.5 lg:hidden">{bars}</div>
                        </div>

                    </div>
                    


                </div>
            </div>
            
            
        </>
    )
}