import cardone from '../images/cardone.webp'
import cardtwo from '../images/cardtwo.webp'
import cardthree from '../images/cardthree.webp'


export default function Cards() {

    return(
        <>
            <section className='max-w-6xl mx-auto px-8 md:px-16 pt-8 pb-16 lg:py-16'>

                <h1 className='text-3xl font-bold mb-6'>About Us</h1>
                
                <div className="grid grid-cols-4 md:grid-cols-8 lg:grid-cols-12 gap-x-4 md:gap-x-8 gap-y-8">
                    <div className='grid col-span-4 self-start order-0'>
                        <div className="flex flex-col items-start">
                            <img src={cardone} className='my-8' alt="taxi" />
                            <div>
                                <p>BlackCab.com is a collaboration of Cabvision Network and Taxiworld who between them have over 14,000 customers. </p>
                            </div>
                        </div>
                    </div>
                    <div className='grid col-span-4 self-start order-0'>
                        <div className="flex flex-col items-start">
                        <img src={cardtwo} className='my-8' alt="taxi"  />
                            <div>
                                <p>BlackCab.com is based in London and is committed to paying all employees the London living wage.</p>
                            </div>
                        </div>
                    </div>
                    <div className='grid col-span-4 self-start order-0'>
                        <div className="flex flex-col items-start">
                        <img src={cardthree} className='my-8' alt="taxi"  />
                            <div>
                                <p>BlackCab.com is a plying for hire app and is committed to working within the Hackney Carriage Act that regulates Black Cab drivers.</p>
                            </div>
                        </div>
                    </div>
                    
                </div>
            </section>
        </>
    )
}